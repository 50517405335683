<template>
  <div>
    <NavSearch />
    <div class="row">
    <div
      v-for="hardware in hardwares"
      :hardware="hardware"
      :key="hardware.id"
      class="col-lg-4"
    >
<!--      <router-link :to="'/event/' + event.id"> --> 
        <HardwareCard :hardware="hardware" />
<!--      </router-link> --> 
    </div>
  </div>
  </div>
</template>
<script>
import HardwareCard from '@/components/HardwareCard';
import NavSearch from "../components/partials/NavSearch.vue";
import hardwareData from "../data/hardwaredata.json";
export default {
  components: {
    NavSearch,
    HardwareCard
  },
  data () {
    return {
      hardware:{},
      hardwares: hardwareData,
    }
  }
};
</script>
<style lang="scss" scoped>
</style>